export default [
  {
    name: 'Crear documentos',
    id: 'add_document',
    icon: 'file-document',
    category: 'Emisión',
    active: false,
    parent: 'Emisión',
    key: 'dtes',
    assignedByGroups: [],
    countries: ['CL']
  },
  {
    name: 'Administrar documentos',
    id: 'add_document',
    icon: 'file-document',
    category: 'Emisión',
    active: false,
    parent: 'Emisión',
    assignedByGroups: [],
    countries: ['PE']
  },
  {
    name: 'Crear resúmenes',
    id: 'add_summary',
    icon: 'file-document',
    parent: 'Emisión',
    active: false,
    category: 'Resúmenes',
    assignedByGroups: [],
    countries: ['PE']
  },
  {
    name: 'Ver resúmenes',
    id: 'view_summary',
    icon: 'file-document',
    parent: 'Emisión',
    active: false,
    category: 'Resúmenes',
    assignedByGroups: [],
    countries: ['PE']
  },
  {
    name: 'Ceder documentos',
    id: 'add_grant',
    icon: 'file-document',
    parent: 'Emisión',
    category: 'Emisión',
    active: false,
    assignedByGroups: [],
    countries: ['CL']
  },
  {
    name: 'Crear proceso de emisión por lote',
    id: 'add_batch',
    icon: 'file-document',
    category: 'Emisión por lotes',
    active: false,
    parent: 'Emisión',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Ver proceso de emisión por lote',
    id: 'view_batch',
    icon: 'file-document',
    parent: 'Emisión',
    active: false,
    category: 'Emisión por lotes',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Editar proceso de emisión por lote',
    id: 'change_batch',
    icon: 'file-document',
    parent: 'Emisión',
    active: false,
    category: 'Emisión por lotes',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Eliminar proceso de emisión por lote',
    id: 'delete_batch',
    icon: 'file-document',
    parent: 'Emisión',
    active: false,
    category: 'Emisión por lotes',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Crear cuadratura',
    id: 'add_reconciliation',
    icon: 'text-box',
    parent: 'Emisión',
    active: false,
    category: 'Cuadratura',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Ver cuadratura',
    id: 'view_reconciliation',
    icon: 'text-box',
    parent: 'Emisión',
    active: false,
    category: 'Cuadratura',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Crear clientes',
    id: 'add_customer',
    icon: 'account-group',
    parent: 'Emisión',
    active: false,
    category: 'Clientes',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Ver clientes',
    id: 'view_customer',
    icon: 'account-group',
    parent: 'Emisión',
    active: false,
    category: 'Clientes',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Descargar SIRE',
    id: 'download_sire',
    icon: 'file-table',
    parent: 'Emisión',
    active: false,
    category: 'SIRE',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Cargar certificado digital',
    id: 'add_genericcertificate',
    icon: 'bank',
    category: 'SII',
    active: false,
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Ver certificado digital',
    id: 'view_genericcertificate',
    icon: 'bank',
    category: 'SII',
    active: false,
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Ver documentos de compra',
    id: 'view_purchase',
    icon: 'file-document',
    category: 'Recepción',
    parent: 'Recepción',
    active: false,
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Ver tienda',
    id: 'view_branch',
    icon: 'store',
    category: 'Tiendas',
    parent: 'Tiendas',
    active: false,
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Editar tienda',
    id: 'change_branch',
    icon: 'store',
    category: 'Tiendas',
    parent: 'Tiendas',
    active: false,
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Ver POS',
    id: 'view_pos',
    icon: 'store',
    category: 'Tiendas',
    parent: 'Recepción',
    active: false,
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Ver organización',
    id: 'view_account',
    icon: 'domain',
    category: 'Organización',
    active: false,
    parent: 'Configuraciones',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Editar organización',
    id: 'change_account',
    icon: 'domain',
    category: 'Organización',
    active: false,
    parent: 'Configuraciones',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Ver invitaciones de usuarios',
    id: 'view_invitation',
    icon: 'account',
    category: 'Usuarios',
    active: false,
    parent: 'Configuraciones',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Enviar invitaciones de usuarios',
    id: 'add_invitation',
    icon: 'account',
    category: 'Usuarios',
    active: false,
    parent: 'Configuraciones',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Eliminar invitaciones de usuarios',
    id: 'delete_invitation',
    icon: 'account',
    category: 'Usuarios',
    active: false,
    parent: 'Configuraciones',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Editar usuarios',
    id: 'change_member',
    icon: 'account',
    category: 'Usuarios',
    active: false,
    parent: 'Configuraciones',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Ver usuarios',
    id: 'view_member',
    icon: 'account',
    category: 'Usuarios',
    active: false,
    parent: 'Configuraciones',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Eliminar usuarios',
    id: 'delete_member',
    icon: 'account',
    category: 'Usuarios',
    active: false,
    parent: 'Configuraciones',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Crear roles de usuarios',
    id: 'add_group',
    icon: 'account-group',
    category: 'Roles',
    active: false,
    parent: 'Configuraciones',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Eliminar rol de usuario',
    id: 'delete_group',
    icon: 'account-group',
    category: 'Roles',
    active: false,
    parent: 'Configuraciones',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Ver roles de usuarios',
    id: 'view_group',
    icon: 'account-group',
    category: 'Roles',
    active: false,
    parent: 'Configuraciones',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Actualizar API Key',
    id: 'change_token',
    icon: 'xml',
    category: 'Developers',
    active: false,
    parent: 'Configuraciones',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  },
  {
    name: 'Ver API Key',
    id: 'view_setupkey',
    icon: 'xml',
    category: 'Developers',
    active: false,
    parent: 'Configuraciones',
    assignedByGroups: [],
    countries: ['CL', 'PE']
  }
]